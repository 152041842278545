import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PrivacyMeta } from "../data/seo-meta"

const personalDataTable = [
  [
    "Contact information",
    "Your first name, last name, company name and email address",
    "To communicate with you",
    "Your consent in giving us this information",
  ],
  [
    "Employment application information*",
    "Your name, your email address, your phone number, your mailing address, and any personal data in your cover letter or CV",
    "To find out if you are a good fit to work with Slice!",
    "Your consent in giving us this information",
  ],
  [
    "Account account information",
    "First and last names, email address, phone number and address of your agency",
    "To create an account for you and to communicate with you about the Platform",
    "Your consent and performance of a contract between you and us",
  ],
  [
    "Billing information",
    "Credit card holder name, number, expiration date, CVV number and billing address (if required by the credit card agreement)",
    "To allow you to pay for the insurance you want to purchase for you or for your Customer if you are an Agent",
    "Performance of a contract between you and us",
  ],
]

const thirdPartyDataTable = [
  [
    "Insurance information",
    "Details about what is to be insured, for example your email related to your business",
    "The Agent who is authorized to buy insurance on your behalf",
    "To quote and bind an insurance product",
    "Your consent",
  ],
]

const titleClass = "p1 font-semibold mb-6"
const contentClass = "p2 mb-6"
const contentClassLast = "p2 mb-32"
const linkClass = "underline"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO
      description={PrivacyMeta.description}
      path={PrivacyMeta.path}
      title={PrivacyMeta.title}
    />
    <div className="container py-20 md:py-24">
      <h1 className="h1 mb-32">Privacy Policy</h1>
      <p className="p1 font-semibold mb-32">(Effective May 2024)</p>
      {/** Welcome Section */}
      <p className={contentClass}>
        Welcome to Slice. We hope you will enjoy and appreciate using our “Services”, which may
        be: (1) visiting or using our websites at&nbsp;
        <Link className={linkClass} to="/">
          https://sliceinsurance.com
        </Link>
        ,&nbsp;
        <Link className={linkClass} to="/">
          https://sliceinsurance.com
        </Link>
        , or other
        websites controlled by us (collectively “Websites”), and any subpages or subdomains
        thereof; or (2) accessing and using the “Platform” available at&nbsp;
        <a
          className={linkClass}
          href="https://app.sliceinsurance.com"
          rel="nofollow noreferrer"
          target="_blank"
        >
          https://app.sliceinsurance.com
        </a>
        &nbsp;that allows you to receive, save, retrieve, and bind quotes
        for various insurance products.
      </p>
      <p className={contentClassLast}>
        We want you to know we take your privacy and protection of personal data very seriously.
        We are providing this Privacy Policy (the “Policy”) to tell you about who we are, what
        personal data we collect from you and about you, and what we do with your personal data,
        all while you use the Services or otherwise interact with us. The Policy also explains your
        rights under the law, and how you can contact us and the necessary authorities to enforce
        those rights. Please read it carefully.
      </p>
      {/** Terms */}
      <h2 className={titleClass}>Some Terms</h2>
      <p className={contentClass}>
        Before we get started, here are a few terms we think you should know as you read this Policy.
      </p>
      <p className={contentClass}>
        “Data Protection Laws” refers to the laws that are designed to protect your personal data
        and privacy in the place where you live. This includes: (1) the “GDPR”, the European data
        protection law which stands for “General Data Protection Regulation”, with the official
        name Regulation (EU) 2016/679 of the European Parliament and of the Council; (2) “PIPEDA”
        (Personal Information Protection and Electronic Documents Act), which is the Canadian Data
        Protection Law that applies to our activities in Canada; (3) the California Consumer Privacy
        Act (“CCPA”) as amended by the California Privacy Rights Act which applies to our activities in
        the United States in certain circumstances; (4) other State privacy laws in the United States,
        specifically those which are currently in force in Colorado, Connecticut, Utah and Virginia;
        and (5) the “UK GDPR” which applies to our activities in the United Kingdom; please note
        that when this Policy refers only to the “GDPR”, this includes the UK GDPR as applicable.
        Slice is committed to adhering to all these applicable Data Protection laws.
      </p>
      <p className={contentClass}>
        “Personal data” &ndash; this is information we collect from you or about you and which is defined
        in the GDPR as “any information relating to an identified or identifiable natural person.” It
        can be as simple as your name or your email, or something more complicated like an
        online identifier (usually a string of letters and / or numbers) that gets attached to you.
        Under PIPEDA and the CCPA, the equivalent concept is “personal information”, which is
        roughly the same. Any mention of “personal data” in this Policy shall also mean personal
        information.
      </p>
      <p className={contentClassLast}>
        Some other definitions may be found in the&nbsp;
        <Link className={linkClass} to="/terms-of-use/">
          Terms of Use
        </Link>
        , and will have the same meaning
        here as they do there.
      </p>
      {/** About Us and Contacting Us */}
      <h2 className={titleClass}>About Us and Contacting Us</h2>
      <p className={contentClass}>
        “Slice” refers to Slice Insurance Technologies Inc., a duly incorporated company in the State
        of Delaware, USA, with an office at the address below, and all of its affiliates, subsidiaries
        and parent companies. Where this Policy refers to “Slice”, it may refer to Slice and/or its
        affiliates, subsidiaries and parent companies and their respective shareholders, officers,
        directors, employees, agents, partners, principals, representatives, successors and assigns
        (collectively “Representatives”), depending on the context. Any reference to “we”, “our”, or
        “us” in this Policy shall also refer to Slice. In this Policy, a Website visitor or Platform user
        may be referred to as “you” or “your”.
      </p>
      <p className={contentClass}>
        Under the GDPR, Slice is a “Data Controller”. That means we collect personal data directly
        from you and determine the purpose and means of “processing” that data. “Processing” is a
        broad term that means collection, use, storage, transfer or any other action related to your
        personal data; it is used in this Policy and other documents linked to from this Policy in that
        way.
      </p>
      <p className={contentClass}>
        If you want to ask us anything about what’s in this Policy, or anything else privacy or data
        related, you can email&nbsp;
        <a
          className={linkClass}
          href="mailto:privacy@sliceinsurance.com"
          rel="nofollow noreferrer"
          target="_blank"
        >
          privacy@sliceinsurance.com
        </a>
        . Here is our mailing address for you as well:
      </p>
      <p className={contentClassLast}>
        Slice Insurance Technologies Inc.<br />
        33 Irving Place, Suite 7116<br />
        New York, NY 10003<br />
        USA
      </p>
      {/** Data Protection Officer */}
      <h2 className={titleClass}>Data Protection Officer</h2>
      <p className={contentClassLast}>
        In addition to the contact information above Slice has designated a Data Protection Officer
        (DPO) who is responsible for all personal data matters at Slice. If you have a specific
        complaint or wish to invoke your legal rights under any Data Protection Law, please contact
        our DPO directly at&nbsp;
        <a
          className={linkClass}
          href="mailto:DPO@slice.is"
          rel="nofollow noreferrer"
          target="_blank"
        >
          DPO@slice.is
        </a>
        .
      </p>
      {/** Your Rights */}
      <h2 className={titleClass}>Your Rights</h2>
      <p className={contentClass}>
        You have the following rights regarding your personal data held by Slice, and other rights.
        Please note that not necessarily all of these rights may be available to you; this depends on
        the Data Protection Laws where you are located that apply to you. These rights may be
        exercised without affecting the price you pay for any of the Services, if any.
      </p>
      <ul className="mb-16 list-disc list-inside">
        <li className={contentClass}>
          The right to withdraw at any time your consent for Slice to process your personal
          data;
        </li>
        <li className={contentClass}>
          The right to have your personal data erased from Slice’s records;
        </li>
        <li className={contentClass}>
          The right to access your personal data and any relevant information around its
          processing and use;
        </li>
        <li className={contentClass}>
          The right to have a copy of your personal data given to you in an easy to read
          format so that you can transfer it to another data processor;
        </li>
        <li className={contentClass}>
          The right to have your personal data corrected or updated if you believe it is
          inaccurate or out of date;
        </li>
        <li className={contentClass}>
          The right to opt out of marketing communications we send you, at any time;
        </li>
        <li className={contentClass}>
          The right to know whether Slice sells or shares your personal data (and if so, who
          gets it). Please refer to that information elsewhere in this Policy, though you can
          contact our us if you need additional information or clarifications;
        </li>
        <li className={contentClass}>
          The right to demand that Slice not sell your Personal Data;
        </li>
        <li className={contentClass}>
          The right to restrict the processing of your personal data if it is inaccurate or if our
          processing or use of it is against the law; and
        </li>
        <li className={contentClass}>
          The right to refuse any marketing or advertising targeted at you by Slice.
        </li>
      </ul>
      <p className={contentClassLast}>
        If you wish to exercise any of these rights, please contact our DPO at
        the contact information above.
      </p>
      {/** Personal Data Collected from You */}
      <h2 className={titleClass}>
        Personal Data Collected from You and What We Use It For
      </h2>
      <p className={contentClass}>
        In the table below, please find all the personal data we may collect from you directly, what
        we use it for, and the legal basis under the GDPR for us having and processing this
        personal data. Under PIPEDA and the CCPA, the legal basis is your informed consent, and
        by submitting this personal data you acknowledge having granted this consent to Slice.
      </p>
      <div className="table-responsive">
        <table className="table-fixed divide-y p1 text-left align-top mb-6">
          <thead>
            <tr>
              <th className="w-3/12 th align-top p-6">
                Personal data category
              </th>
              <th className="w-3/12 th align-top p-6">
                Personal data processed
              </th>
              <th className="w-3/12 th align-top p-6">
                What we use it for (the “purpose” of processing)
              </th>
              <th className="w-3/12 th align-top p-6">
                Legal basis for processing under the GDPR
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {personalDataTable.map((row) => (
              <tr key={row[0]}>
                {row.map((rowItem) => (
                  <td key={rowItem} className="p2 align-top p-6">
                    {rowItem}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={contentClass}>
        <em>
          *Your employment application information is collected via the
          third-party service&nbsp;
          <a
            className={linkClass}
            href="https://www.bamboohr.com/"
            rel="noreferrer nofollow"
            target="_blank"
          >
            BambooHR
          </a>
          ; once received by Slice from BambooHR it will be treated in
          accordance with this Policy.
        </em>
      </p>
      <p className={contentClassLast}>
        Where you have provided personal data further to the contract between you and us, if you
        fail to provide such data or withdraw your consent to use such data, we will no longer be
        able to sell you insurance or process your claim.
      </p>
      {/** Personal Data Collected About You from Third Parties */}
      <h2 className={titleClass}>
        Personal Data Collected About You from Third Parties and What We Use It
        For
      </h2>
      <p className={contentClass}>
        Sometimes we get personal data about you from third parties. This table explains the
        details about this personal data &ndash; what it is, where it came from, what we do with it, and
        the legal basis for us having and processing this personal data under the GDPR. Under
        PIPEDA and the CCPA, the legal basis is your informed consent. None of this data comes
        from publicly-available sources.
      </p>
      <div className="table-responsive">
        <table className="table-fixed divide-y p1 text-left align-top mb-6">
          <thead>
            <tr>
              <th className="w-1/5 th align-top p-6">Personal data category</th>
              <th className="w-1/5 th align-top p-6">
                Personal data processed
              </th>
              <th className="w-1/5 th align-top p-6">
                Who we get the data from
              </th>
              <th className="w-1/5 th align-top p-6">
                What we use it for (the “purpose” of processing)
              </th>
              <th className="w-1/5 th align-top p-6">
                Legal basis for processing under the GDPR
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {thirdPartyDataTable.map((row) => (
              <tr key={row[0]}>
                {row.map((rowItem) => (
                  <td key={rowItem} className="p2 align-top p-6">
                    {rowItem}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={contentClassLast}>
        Furthermore, to the extent that analytics identifiers and advertising identifiers are
        generated from third parties, these may be considered personal data collected from third
        parties, and you can find details about that further below in this Policy. If we ever collect
        other personal data from you from third parties, we will ensure that we have a legal basis
        to process such personal data.
      </p>
      {/** Who We Transfer Your Personal Data To */}
      <h2 className={titleClass}>Who We Transfer Your Personal Data To</h2>
      <p className={contentClass}>
        We routinely share some of your personal data with certain types of third parties who are
        identified in the table below along with what they do with it. Some of those third-party
        recipients may be based outside your home jurisdiction. If you are in the European
        Economic Area or the United Kingdom— please see the “Transfer of Your Personal Data
        Outside of the European Economic Area” further down in this Policy for more information
        including on how we safeguard your personal data when this occurs.
      </p>
      <p className={contentClass}>
        We will share personal data with law enforcement or other public authorities if: (1) we are
        required by applicable law in response to lawful requests, including to meet national
        security or law enforcement requirements; (2) if we believe it is necessary in order to
        investigate, prevent, or take action regarding illegal activities, fraud, or situations involving
        potential threats to the safety of any person, or any violation of Slice’s Terms of Use; or (3)
        if we believe it is necessary to investigate, prevent, or take action regarding situations that
        involve abuse of the Services infrastructure or the Internet in general (such as voluminous
        spamming, denial of service attacks. We may also share personal data: (1) to a parent
        company, subsidiaries, joint ventures, or other companies under common control with
        Slice (in which case we will require such entities to honour this Policy); (2) if Slice merges
        with another entity, is subject to a corporate reorganization, sells or transfers all or part of
        its business, assets or shares (in which case we will require such entity to assume our
        obligations under this Policy, or inform you that you are covered by a new privacy policy).
      </p>
      <p className={contentClass}>
        We will never share your personal data with other third parties except under these
        circumstances. We do not sell your personal data to any third party for direct marketing
        purposes or any other purpose.
      </p>
      <div className="table-responsive">
        <table className="table-fixed divide-y p1 text-left align-top mb-32">
          <thead>
            <tr>
              <th className="w-1/3 th align-top p-6">Personal data category</th>
              <th className="w-1/3 th align-top p-6">Who we transfer it to</th>
              <th className="w-1/3 th align-top p-6">What they do with it</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            <tr>
              <td className="p2 align-top p-6">Contact information</td>
              <td className="p2 align-top p-6">
                <ul className="list-disc list-inside">
                  <li className="mb-6">
                    Companies that provide email or SMS services
                  </li>
                  <li className="mb-6">
                    Companies that provide CRM Services
                  </li>
                </ul>
              </td>
              <td className="p2 align-top p-6">
                Send you emails or SMS messages; store it so that we may
                retrieve it to contact you
              </td>
            </tr>
            <tr>
              <td className="p2 align-top p-6">
                Agent Account information
              </td>
              <td className="p2 align-top p-6">
                <ul className="list-disc list-inside">
                  <li className="mb-6">
                    Insurance companies that underwrite insurance
                  </li>
                  <li className="mb-6">
                    Companies providing technical infrastructure
                  </li>
                </ul>
              </td>
              <td className="p2 align-top p-6">
                Communicate with you about quotes, various needs to sell
                insurance, and record-keeping
              </td>
            </tr>
            <tr>
              <td className="p2 align-top p-6">Billing information</td>
              <td className="p2 align-top p-6">
                Payment processing companies, particularly{" "}
                <a
                  className={linkClass}
                  href="https://stripe.com"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  Stripe
                </a>
                ; you can find more about how Stripe
                treats your billing information at&nbsp;
                <a
                  className={linkClass}
                  href="https://stripe.com/privacy"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  https://stripe.com/privacy
                </a>
              </td>
              <td className="p2 align-top p-6">
                Process payments for insurance purchased on the Platform
              </td>
            </tr>
            <tr>
              <td className="p2 align-top p-6">Advertising identifiers</td>
              <td className="p2 align-top p-6">
                Companies that provide ad networks, like Google
              </td>
              <td className="p2 align-top p-6">
                Show you ads for Slice when you are on the internet
              </td>
            </tr>
            <tr>
              <td className="p2 align-top p-6">Analytics identifiers</td>
              <td className="p2 align-top p-6">
                Companies that provide data analytics
              </td>
              <td className="p2 align-top p-6">
                Provide us with analytics as to how the services are used, and
                to trace fraudulent activities
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/** Tracking Technology */}
      <h2 className={titleClass}>
        Tracking Technology (“Cookies” and Related Technologies) and Advertising
      </h2>
      <p className={contentClassLast}>
        Slice uses tracking technology (“cookies”) and other related technologies in our Services,
        which perform a number of functions including serving you advertisements. For details
        about Slice’s use of cookies, other tracking technologies and advertisements, please read
        our&nbsp;
        <Link className={linkClass} to="/cookie-policy/">
          Cookie and Advertising Policy
        </Link>
        .
      </p>
      {/** How We Protect Your Personal Data */}
      <h2 className={titleClass}>How We Protect Your Personal Data</h2>
      <p className={contentClass}>
        We have implemented very strict technical and organisational procedures for ensuring
        that, by default, only personal data which are necessary for each specific purpose of the
        processing are processed by us. These procedures prevent your personal data from being
        lost; or used or accessed in any unauthorised way.
      </p>
      <p className={contentClass}>
        We also have procedures in place to deal with any suspected data security breach. We will
        notify you and any applicable supervisory authority of a suspected data security breach
        where the Data Protection Laws requires us to do so, and within the time frame required
        by the applicable Data Protection Law.
      </p>
      <p className={contentClass}>
        Slice uses only industry best practices (physical, electronic and procedural) in keeping any
        data collected (including personal data) secure. In addition, we have very strict security
        protocols for hosting of the Websites and Platform, and collection and storage of data
        including personal data. We use third-party vendors and hosting partners to provide the
        necessary hardware, software, networking, storage, and related technology required to
        operate the Websites and Platform, and these third parties have been selected for their
        high standards of security, both electronic and physical.
      </p>
      <p className={contentClass}>
        All personal data is transferred with encryption using Transport Layer Security (“TLS”), the
        highest security standard for Internet transactions. You can use your browser to check
        Slice’s valid SSL security certificates on the Websites and Platform.
      </p>
      <p className={contentClassLast}>
        Please be aware that despite our safeguards and efforts to secure your information, no
        electronic transmission over the Internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to defeat our security,
        and improperly collect, access, steal, or modify information, including your personal data.
        Although we will do our best to protect your personal data, transmission of personal data
        to and from our Websites and Platform is at your own risk. You should only access the
        Websites and Platform from within a secure environment.
      </p>
      {/** Transfer of Your Personal Data Outside of the European Economic Area */}
      <h2 className={titleClass}>
        Transfer of Your Personal Data Outside of the European Economic Area
        (EEA) and the United Kingdom (U.K.)
      </h2>
      <p className={contentClass}>
        For our European users, we endeavour to keep your personal data inside the EEA or the
        U.K. (as applicable). However, certain of our data processors (and some of Slice!) are in
        other countries where your personal data may be transferred. However, these countries
        are limited to countries with particular circumstances that protect your data, specifically:
      </p>
      <ul className="list-disc list-inside">
        <li className={contentClass}>
          Canada. Canada has been determined to have an “adequate level of
          protection” for your personal data&nbsp;
          <a
            className={linkClass}
            href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
            rel="nofollow noreferrer"
            target="_blank"
          >
            under European data protection law
          </a>
          .
        </li>
        <li className={contentClass}>
          The United States. Your personal data is only transferred to companies
          in the United States that: (1) have signed agreements with us or have
          informed us that they are GDPR-compliant; and (2) have concluded
          the&nbsp;
          <a
            className={linkClass}
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Standard Contractual Clauses
          </a>
          &nbsp;for the transfer of personal data outside the EEA or the U.K.
        </li>
      </ul>
      <p className={contentClassLast}>
        That’s it! You have the right, however, to refuse to have your data transferred outside the
        EEA or the U.K. Please contact our DPO to make that request. Please note that making this
        request may prevent you from being able to use a portion or all of the Services.
      </p>
      {/** Supervisory Authorities and Complaints */}
      <h2 className={titleClass}>Supervisory Authorities and Complaints</h2>
      <p className={contentClass}>
        If you are in the EEA or the U.K., under the GDPR you have the right to make a complaint to
        the appropriate supervisory authority. If you are not satisfied with the response received or
        the actions taken by our DPO, or if you would like to make a complaint directly about Slice’s
        data practices, we invite you to contact the supervisory authority in your country. If you are
        in the U.K., you should contact the Information Commissioner’s Office who is the
        supervisory authority. You can&nbsp;
        <a
          className={linkClass}
          href="https://ico.org.uk/global/contact-us/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          reach them in a variety of ways
        </a>
        , including by phone (0303 123 1113 in the UK) and mail (Wycliffe House,
        Water Lane, Wilmslow, Cheshire, SK9 5AF).
      </p>
      <p className={contentClass}>
        The full listing of all Data Protection Authorities (the supervisory
        authorities) across the EEA&nbsp;
        <a
          className={linkClass}
          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
          rel="nofollow noreferrer"
          target="_blank"
        >
          can be found here
        </a>
        .
      </p>
      <p className={contentClass}>
        In addition to the European Data Protection Authorities, Slice is subject to the investigatory
        and enforcement powers of the&nbsp;
        <a
          className={linkClass}
          href="https://www.ftc.gov/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          Federal Trade Commission
        </a>
        &nbsp;(FTC). If you are in California
        and you are not satisfied with the response received or the actions taken by our DPO, you
        can make a complaint to the California Attorney General,&nbsp;
        <a
          className={linkClass}
          href="https://oag.ca.gov/contact/consumer-complaint-against-business-or-company"
          rel="nofollow noreferrer"
          target="_blank"
        >
          via their website
        </a>
        .
      </p>
      <p className={contentClassLast}>
        Finally, if you are in Canada and you are not satisfied with the response received or the
        actions taken by our DPO, you can make a complaint to the Office of the Privacy
        Commissioner of Canada. Instructions on how to do so can be found&nbsp;
        <a
          className={linkClass}
          href="https://www.priv.gc.ca/en/report-a-concern/file-a-formal-privacy-complaint/"
          rel="nofollow noreferrer"
          target="_blank"
        >
          on their website
        </a>
        .
      </p>
      {/** Data Retention */}
      <h2 className={titleClass}>Data Retention</h2>
      <p className={contentClass}>
        Your personal data will only be kept for as long as it is necessary for the purpose needed
        for that processing. For example, we will retain your account information for as long as you
        have an account with us.
      </p>
      <p className={contentClassLast}>
        Because insurance is a heavily regulated industry, sometimes we have to keep your data
        for a longer period of time to satisfy our requirements under the law, so we may retain
        your personal data for a longer time based on this factor.
      </p>
      {/** Automated Decision-Making */}
      <h2 className={titleClass}>Automated Decision-Making</h2>
      <p className={contentClassLast}>
        Slice uses certain automated decision-making processes, to determine the cost of
        insurance quoted on the Platform.
      </p>
      {/** Children’s Privacy Statement */}
      <h2 className={titleClass}>Children’s Privacy Statement</h2>
      <p className={contentClassLast}>
        The Services are not intended for children under the age of 18. We do not knowingly collect
        any personal data from a child under 18. If we become aware that we have inadvertently
        received personal data from a person under the age of 18 through the Services, we will
        delete such information from our records.
      </p>
      {/** Changes to This Privacy Notice */}
      <h2 className={titleClass}>Changes to This Privacy Notice</h2>
      <p className={contentClassLast}>
        The date at the top of this page indicates when this Policy was last updated. Every now and
        then, we will have to update this Policy, and we will update it no less than once every 12
        months. You can always find the most updated version at this URL, and we will always post
        a notice on our Websites if we make big changes. If you have a Slice account, we will also
        email you to tell you the Policy has been updated, and what the important changes are.
      </p>
      {/** Copyright */}
      <p className={contentClassLast}>&copy; Slice Insurance Technologies Inc. 2024</p>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
